#embedmap-canvas img{
    max-width:none!important;
    background:none!important;
    font-size: inherit;
    font-weight:inherit;
}

.container {
    max-width: 500px !important;
    margin: 0 auto !important;
}